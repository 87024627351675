// material-ui
import { useTheme } from '@mui/material/styles';
import logo from 'assets/images/logo.jpg';
import logoDark from 'assets/images/logo-dark.svg';

const Logo = () => {
    const theme = useTheme();

    return (
        <div style={{ width: '100px', padding: '5px' }}>
            <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="logo" style={{ width: '80%' }} />
        </div>
    );
};

export default Logo;
