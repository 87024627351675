import { useTheme, styled } from '@mui/material/styles';
import { Grid, Typography, CardContent } from '@mui/material';
import { gridSpacing } from 'store/constant';

export type plan = {
    id: number;
    popular: boolean;
    title: string;
    price: {
        monthly: number;
        yearly: number;
    };
};

export const FeatureContentWrapper = styled(CardContent)(({ theme }) => ({
    borderLeft: '1px solid',
    borderColor: theme.palette.mode === 'dark' ? `${theme.palette.background.default} !important` : `${theme.palette.grey[200]} !important`,
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.down('lg')]: {
        fontSize: '1.25rem',
        padding: '40px 16px'
    }
}));

const PopularBadgeWrapper = styled('div')(({ theme }) => ({
    background: theme.palette.secondary.main,
    color: '#fff',
    display: 'inline-block',
    padding: '40px 40px 5px',
    fontSize: '0.8125rem',
    position: 'absolute',
    top: -24,
    right: -55,
    transform: 'rotate(45deg)'
}));

const PlanList = ({ plan, view, priceFlag }: { plan: plan; view: number; priceFlag: string }) => {
    const theme = useTheme();

    return (
        <Grid item xs={12} sm={3} md={3} sx={{ display: view !== plan.id ? { xs: 'none', sm: 'block' } : 'block' }}>
            <FeatureContentWrapper>
                {plan.popular && <PopularBadgeWrapper>Popular</PopularBadgeWrapper>}
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                        <Typography
                            variant="h6"
                            sx={{ fontSize: '1.25rem', fontWeight: 500, position: 'relative', color: theme.palette.primary.main }}
                        >
                            {plan.title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            variant="body2"
                            sx={{
                                fontSize: '1.5rem',
                                fontWeight: 700,
                                '& > span': {
                                    fontSize: '1.25rem',
                                    fontWeight: 500
                                },
                                [theme.breakpoints.down('lg')]: {
                                    fontSize: '1.25rem',
                                    '& > span': {
                                        fontSize: '1rem'
                                    }
                                }
                            }}
                        >
                            <sup>$</sup>
                            {priceFlag === 'yearly' ? plan.price.yearly : plan.price.monthly}
                            <span>/{priceFlag === 'yearly' ? 'Year' : 'Month'}</span>
                        </Typography>
                    </Grid>
                </Grid>
            </FeatureContentWrapper>
        </Grid>
    );
};

export default PlanList;
