// import { useState } from 'react';
import { Box, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import langString from 'utils/langString';
import { endTime, scheduleDay, startTime, WeekDays } from 'utils/date';

type ScheduleInputPropsType = {
    schedule: string;
    error: any;
    updateSchedule: (data: string) => void;
};
export default function ScheduleInput({ error, schedule, updateSchedule }: ScheduleInputPropsType) {
    // const [day, setDay] = useState(WeekDays[0]);
    // const [start, setStart] = useState('');
    // const [end, setEnd] = useState('');

    const processScheduleTime = (value: string, slot: string) => {
        if (slot === 'day') {
            updateSchedule(`${value} ${startTime(schedule)}-${endTime(schedule)}`);
        }
        if (slot === 'start') {
            updateSchedule(`${scheduleDay(schedule)} ${value}-${endTime(schedule)}`);
        }
        if (slot === 'end') {
            updateSchedule(`${scheduleDay(schedule)} ${startTime(schedule)}-${value}`);
        }
    };

    return (
        <Box sx={{ marginBottom: 2 }}>
            <Grid container spacing={1} sx={{ marginY: 1 }}>
                <Grid item sm={4}>
                    <FormControl fullWidth>
                        <InputLabel id="schedule-select-label">{langString('day')}</InputLabel>
                        <Select
                            size="small"
                            name="day"
                            labelId="day-select-label"
                            id="day-select"
                            value={scheduleDay(schedule)}
                            label={langString('day')}
                            onChange={(event: any) => processScheduleTime(event.target.value, 'day')}
                        >
                            {WeekDays.map((dayName) => (
                                <MenuItem key={dayName} value={dayName}>
                                    {dayName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item sm={4}>
                    <FormControl fullWidth>
                        <TextField
                            size="small"
                            id="starttime"
                            type="time"
                            inputProps={{
                                step: 300
                            }}
                            placeholder={langString('starttime')}
                            defaultValue={startTime(schedule)}
                            onChange={(event: any) => processScheduleTime(event.target.value, 'start')}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={4}>
                    <FormControl fullWidth>
                        <TextField
                            size="small"
                            id="endtime"
                            type="time"
                            inputProps={{
                                step: 300
                            }}
                            placeholder={langString('endtime')}
                            defaultValue={endTime(schedule)}
                            onChange={(event: any) => processScheduleTime(event.target.value, 'end')}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            {error && error.required && (
                <FormHelperText error id="helper-text-error">
                    {error?.message}
                </FormHelperText>
            )}
        </Box>
    );
}
