import { Box, Grid } from '@mui/material';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircle';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';

const PlanListItem = ({ item, index, view }: { item: number; index: number; view: number }) => (
    <Grid item xs={4} sm={3} md={3} sx={{ display: view !== index + 1 ? { xs: 'none', sm: 'block' } : 'block' }}>
        {item === 1 && (
            <Box sx={{ px: 3, py: 1.5 }}>
                <CheckCircleTwoToneIcon sx={{ color: 'success.dark' }} />
            </Box>
        )}
        {item === 0 && (
            <Box sx={{ px: 3, py: 1.5 }}>
                <RemoveRoundedIcon sx={{ opacity: '0.3' }} />
            </Box>
        )}
    </Grid>
);

export default PlanListItem;
