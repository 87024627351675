import { useState } from 'react';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Box, Button, ButtonGroup, Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';

// assets
import ToggleButton from '@mui/material/ToggleButton';
import PlanList, { plan } from './components/PlanList';
import OrderButton from './components/OrderButton';
import PlanListItem from './components/PlanListItem';

// styles
const FeatureTitleWrapper = styled(CardContent)(({ theme }) => ({
    background: theme.palette.mode === 'dark' ? `${theme.palette.background.default} !important` : `${theme.palette.grey[100]} !important`,
    textAlign: 'left',
    paddingTop: 12,
    paddingBottom: '12px !important'
}));

const plans: plan[] = [
    {
        id: 1,
        popular: false,
        title: 'Starters',
        price: {
            monthly: 25,
            yearly: 225
        }
    },
    {
        id: 2,
        popular: true,
        title: 'Scalability',
        price: {
            monthly: 125,
            yearly: 825
        }
    },
    {
        id: 3,
        popular: false,
        title: 'Enterprise',
        price: {
            monthly: 225,
            yearly: 1025
        }
    }
];

export type planListItem = {
    type: string;
    label: string;
    permission?: number[];
};

const planList: planListItem[] = [
    {
        type: 'group',
        label: 'Features'
    },
    {
        type: 'list',
        label: 'Only 1 User uses',
        permission: [1, 1, 1]
    },
    {
        type: 'list',
        label: '10 Projects for',
        permission: [0, 1, 1]
    },
    {
        type: 'list',
        label: 'Unlimited Bandwidth',
        permission: [0, 0, 1]
    },
    {
        type: 'list',
        label: 'Unlimited Data',
        permission: [0, 0, 1]
    },
    {
        type: 'group',
        label: 'Storage & Security'
    },
    {
        type: 'list',
        label: '5GB of Storage',
        permission: [0, 1, 1]
    },
    {
        type: 'list',
        label: 'Fully Security Suite',
        permission: [0, 0, 1]
    }
];

// =============================|| PRICING - PRICE 2 ||============================= //

const Packages = () => {
    const theme = useTheme();
    const [priceFlag, setPriceFlag] = useState('monthly');

    const [view, setView] = useState(1);
    const handleChange = (event: React.SyntheticEvent, newView: number) => {
        if (newView !== null) {
            setView(newView);
        }
    };

    return (
        <Box sx={{ marginTop: 2 }}>
            <Stack direction="row" spacing={gridSpacing} justifyContent="center">
                <ButtonGroup disableElevation variant="contained" sx={{ mb: 3 }}>
                    <Button
                        size="large"
                        sx={{ bgcolor: priceFlag === 'yearly' ? 'primary.main' : 'primary.200' }}
                        onClick={() => setPriceFlag('yearly')}
                    >
                        Annual
                    </Button>
                    <Button
                        size="large"
                        sx={{ bgcolor: priceFlag === 'monthly' ? 'primary.main' : 'primary.200' }}
                        onClick={() => setPriceFlag('monthly')}
                    >
                        Monthly
                    </Button>
                </ButtonGroup>
            </Stack>

            <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                    <Card sx={{ display: { xs: 'block', sm: 'none' } }}>
                        <CardContent>
                            <ToggleButtonGroup
                                orientation="vertical"
                                value={view}
                                exclusive
                                onChange={handleChange}
                                sx={{
                                    width: '100%',
                                    '& > button': {
                                        border: 'none',
                                        borderRadius: '5px ​!important'
                                    },
                                    '& > button.Mui-selected': {
                                        background: `${theme.palette.background.default}!important`,
                                        color: theme.palette.primary.main
                                    }
                                }}
                            >
                                {plans.map((planItem, index) => (
                                    <ToggleButton key={index} value={planItem.id}>
                                        {planItem.title}
                                    </ToggleButton>
                                ))}
                            </ToggleButtonGroup>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <MainCard content={false} sx={{ textAlign: 'center' }}>
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={3} md={3} />
                            {plans.map((planItem, index) => (
                                <PlanList plan={planItem} view={view} priceFlag={priceFlag} key={index} />
                            ))}
                        </Grid>
                        {planList.map((list, index) => (
                            <Box key={index}>
                                {list.type === 'group' && (
                                    <FeatureTitleWrapper>
                                        <Typography variant="subtitle1">{list.label}</Typography>
                                    </FeatureTitleWrapper>
                                )}
                                {list.type === 'list' && (
                                    <Grid
                                        container
                                        spacing={0}
                                        sx={{
                                            borderBottom: '1px solid',
                                            borderColor:
                                                theme.palette.mode === 'dark'
                                                    ? `${theme.palette.background.default} !important`
                                                    : `${theme.palette.grey[200]} !important`
                                        }}
                                    >
                                        <Grid item xs={8} sm={3} md={3}>
                                            <Box sx={{ px: 3, py: 1.5 }}>
                                                <Typography component="div" align="left" variant="body2">
                                                    {list.label}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        {list.permission?.map((item, i) => (
                                            <PlanListItem key={i} item={item} index={index} view={view} />
                                        ))}
                                    </Grid>
                                )}
                            </Box>
                        ))}
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={3} md={3} />
                            <OrderButton view={view} index={1} />
                            <OrderButton view={view} index={2} popular />
                            <OrderButton view={view} index={3} />
                        </Grid>
                    </MainCard>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Packages;
