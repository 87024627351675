import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Wrapper from 'components/Card/Wrapper';
import langString from 'utils/langString';
import axiosServices from 'utils/axiosServices';
import { SnackBarType } from 'types/common';
import { checkNullInfo, makeFormData, requestUrl } from 'utils/Helpers';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { useSelector } from 'react-redux';
import { DefaultRootStateProps } from 'types';
import { useParams } from 'react-router-dom';
import EnhancedTable from 'components/EnhancedTable';
import { HeadCellType } from 'components/EnhancedTable/Header';

export default function ReportParticipants() {
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState<SnackBarType>({ open: false, message: '' });
    const [quizDetails, setQuizDetails] = useState<any>(null);
    const [students, setStudents] = useState<any[]>([]);
    const course = useSelector((state: DefaultRootStateProps) => state.courseData);
    const { quizID } = useParams();

    const StudentColumns: HeadCellType[] = [
        {
            disablePadding: true,
            label: langString('name'),
            id: 'name',
            numeric: false
        },
        { disablePadding: true, label: langString('earnedmark'), id: 'earnedmark', numeric: true },
        { disablePadding: true, label: langString('totalmark'), id: 'totalmark', numeric: true },
        { disablePadding: true, label: langString('attempt'), id: 'attempt', numeric: true }
    ];

    useEffect(() => {
        const init = async () => {
            setLoading(true);
            try {
                const response = await axiosServices.post(
                    requestUrl('ace_exam', 'quiz_participants'),
                    makeFormData([
                        { key: 'course_id', value: course?.id },
                        { key: 'quiz_id', value: quizID || 0 }
                    ])
                );
                if (response.data.success) {
                    if (response.data?.response) {
                        setStudents(response.data.response);
                    }
                    setLoading(false);
                }
            } catch (e) {
                console.log(e);
                setLoading(false);
                setSnackbar({ open: true, message: langString('servererror') });
            }
        };
        if (course && quizID) {
            init();
        } else {
            setSnackbar({ open: true, message: langString('coursenotselected') });
        }
    }, [quizID, course]);

    useEffect(() => {
        const init = async () => {
            setLoading(true);
            try {
                const response = await axiosServices.post(
                    requestUrl('ace_exam', 'get_quiz_details'),
                    makeFormData([{ key: 'quiz_id', value: quizID || 0 }])
                );
                if (response.data.success) {
                    if (response.data?.response) {
                        setQuizDetails(response.data.response);
                    }
                    setLoading(false);
                }
            } catch (e) {
                console.log(e);
                setLoading(false);
                setSnackbar({ open: true, message: langString('servererror') });
            }
        };
        if (quizID) {
            init();
        } else {
            setSnackbar({ open: true, message: langString('invaliddata') });
        }
    }, [quizID]);

    return (
        <Wrapper
            title={`${langString('participants')}: ${checkNullInfo(quizDetails?.details?.name)} `}
            icon={<AssessmentIcon />}
            snakbar={snackbar}
            snackbarClose={() => setSnackbar({ open: false, message: '' })}
            loading={loading}
        >
            <Grid container>
                <Grid item sm={12}>
                    <EnhancedTable idField="id" rows={students} headerCells={StudentColumns} />
                </Grid>
            </Grid>
        </Wrapper>
    );
}
