import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import _ from 'lodash';

export function dateTimeDuration(date: string) {
    const dateTime = dayjs(date);
    return `Hour: ${dateTime.format('h')}  Minute: ${dateTime.minute()} ${dateTime.format('A')}`;
}
export function isPrevDate(date: string) {
    dayjs.extend(relativeTime);
    return dayjs().isBefore(dayjs(date));
}

export const WeekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
export const DayHours = _.range(0, 25, 1);
export const Minutes = _.range(0, 61, 1);

export const scheduleDay = (schedule: string) => {
    const daySplit = schedule.split(' ');
    if (daySplit.length) {
        return daySplit[0];
    }
    return 'Sunday';
};

export const schedulePeriod = (schedule: string) => {
    const daySplit = schedule.split(' ');
    if (daySplit.length > 1) {
        return daySplit[1];
    }
    return '09:00-10:00';
};
export const startTime = (schedule: string) => {
    const daySplit = schedule.split(' ');
    if (daySplit.length > 1 && daySplit[1].includes('-')) {
        const timeSplit = daySplit[1].split('-');
        if (timeSplit.length) {
            return timeSplit[0];
        }
    }

    return '00:00';
};
export const endTime = (schedule: string) => {
    const daySplit = schedule.split(' ');
    if (daySplit.length > 1 && daySplit[1].includes('-')) {
        const timeSplit = daySplit[1].split('-');
        if (timeSplit.length > 1) {
            return timeSplit[1];
        }
    }
    return '00:00';
};
