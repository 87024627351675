import { Typography, Grid } from '@mui/material';
import ContentViewer from 'components/common/ContentViewer';
import { IQuestion, IViewQuestion } from 'types/common';
import { answerOption } from 'utils/Helpers';

export default function PdfViewer({
    questions,
    divRef,
    answerDivRef,
    title
}: {
    questions: IQuestion[] | IViewQuestion[];
    divRef?: React.MutableRefObject<HTMLDivElement | null>;
    answerDivRef?: React.MutableRefObject<HTMLDivElement | null>;
    title?: string;
}) {
    return (
        <>
            <div id="printable" ref={divRef} style={{ display: answerDivRef && 'none' }}>
                {title && (
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item>
                            <Typography variant="h2" component="h2">
                                {title.toUpperCase()}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                {questions.map((e, key) => (
                    <div
                        style={{
                            paddingLeft: 32,
                            paddingRight: 32,
                            paddingTop: 24,
                            color: 'black',
                            breakInside: 'avoid',
                            fontSize: '1.3em'
                        }}
                    >
                        <div>
                            <div>
                                <ContentViewer
                                    content={e.questiontext}
                                    concatableString={`<h3 style="color: black; margin: 0">${key + 1}.&nbsp &nbsp</h3>`}
                                />
                            </div>
                            <Grid container>
                                {e.answers &&
                                    e.answers.map((ans: any, ansKey: number) => (
                                        <Grid item xs={6} paddingX={3.5} paddingY={0.5} maxWidth="100%">
                                            <ContentViewer
                                                content={ans.answer}
                                                concatableString={`<b style="font-size: 16px; color: black">${answerOption(
                                                    ansKey
                                                )}. &nbsp</b>`}
                                            />
                                        </Grid>
                                    ))}
                            </Grid>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}
