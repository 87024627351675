// action - account reducer
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const USER_UPDATE = 'USER_UPDATE';
// Course
export const COURSE_SELECT = 'COURSE_SELECT';
export const COURSE_UPDATE = 'COURSE_UPDATE';
export const COURSE_REOMVE = 'COURSE_REOMVE';
// Question
export const ADD_SELECTED_QUESTION = 'ADD_SELECTED_QUESTION';
export const REMOVE_QUESTION = 'REMOVE_QUESTION';
export const ADD_ALL_QUESTIONS = 'ADD_ALL_QUESTIONS';
export const REMOVE_ALL_SELECTED_QUESTIONS = 'REMOVE_ALL_SELECTED_QUESTIONS';

export const FIREBASE_STATE_CHANGED = 'FIREBASE_STATE_CHANGED';

// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const MENU_TYPE = '@customization/MENU_TYPE';
export const PRESET_COLORS = '@customization/PRESET_COLORS';
export const THEME_LOCALE = '@customization/THEME_LOCALE';
export const THEME_RTL = '@customization/THEME_RTL';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';
export const SET_OUTLINED_FILLED = '@customization/SET_OUTLINED_FILLED';

// action - snackbar
export const SNACKBAR_OPEN = '@snackbar/SNACKBAR_OPEN';
