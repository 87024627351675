import { Button, Grid } from '@mui/material';
import { FeatureContentWrapper } from './PlanList';

const OrderButton = ({ view, index, popular }: { view: number; index: number; popular?: boolean }) => (
    <Grid item xs={12} sm={3} md={3} sx={{ display: view !== index ? { xs: 'none', sm: 'block' } : 'block' }}>
        <FeatureContentWrapper>
            <Button variant={popular ? 'contained' : 'outlined'} color={popular ? 'secondary' : 'primary'}>
                Order Now
            </Button>
        </FeatureContentWrapper>
    </Grid>
);
export default OrderButton;
