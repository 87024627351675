import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import snackbarReducer from './snackbarReducer';
import accountReducer from './accountReducer';
import courseReducer from './courseReducer';
import questionReducer from './questionReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    snackbar: snackbarReducer,
    account: accountReducer,
    courseData: courseReducer,
    questionData: questionReducer
});

export default reducer;
