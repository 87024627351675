import { useEffect, useState } from 'react';
import { Grid, TextField } from '@mui/material';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import Wrapper from 'components/Card/Wrapper';
import langString from 'utils/langString';
import axiosServices from 'utils/axiosServices';
import { SnackBarType } from 'types/common';
import { makeFormData, requestUrl } from 'utils/Helpers';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ConfirmButton from 'components/common/ConfirmButton';
import BasicDataTable, { ColumnType } from 'components/Table/BasicDataTable';

export default function EnrolmentRequests() {
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState<SnackBarType>({ open: false, message: '' });
    const [students, setStudents] = useState<any[]>([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [search, setSearch] = useState('');

    const StudentColumns: ColumnType[] = [
        {
            header: langString('name'),
            accessor: 'name'
        },
        {
            header: langString('phone'),
            accessor: 'phone'
        },
        {
            header: langString('teacher'),
            accessor: 'creator_name'
        },
        {
            header: langString('approve'),
            accessor: 'action',
            content: (item: any) => (
                <>
                    <ConfirmButton
                        icon={<AddCircleIcon />}
                        subTitle={`${langString('approve')} ${item.name}`}
                        confirmed={() => approveUser(item.id)}
                    />
                </>
            )
        }
    ];

    const approveUser = async (id: number) => {
        setLoading(true);
        try {
            const response = await axiosServices.post(
                requestUrl('ace_exam', 'approve_user'),
                makeFormData([{ key: 'approval_id', value: id }])
            );
            if (response.data.success) {
                refreshList();
                setSnackbar({ open: true, message: langString('studentapprovedsuccessfully'), alert: 'success' });
            } else {
                setSnackbar({ open: true, message: response.data?.msg, alert: 'error' });
            }
            setLoading(false);
        } catch (e) {
            console.log(e);
            setLoading(false);
            setSnackbar({ open: true, message: langString('servererror') });
        }
    };

    const refreshList = async () => {
        setLoading(true);
        try {
            const response = await axiosServices.post(
                requestUrl('ace_exam', 'enrolment_requests'),
                makeFormData([
                    { key: 'search', value: search },
                    { key: 'rows_per_page', value: rowsPerPage },
                    { key: 'page_no', value: page }
                ])
            );
            if (response.data.success) {
                if (response.data?.response?.data) {
                    setStudents(response.data.response?.data);
                    setCount(response.data.response?.totalCount);
                }
                setLoading(false);
            }
        } catch (e) {
            console.log(e);
            setLoading(false);
            setSnackbar({ open: true, message: langString('servererror') });
        }
    };

    useEffect(() => {
        const init = async () => {
            setLoading(true);
            try {
                const response = await axiosServices.post(
                    requestUrl('ace_exam', 'enrolment_requests'),
                    makeFormData([
                        { key: 'search', value: search },
                        { key: 'rows_per_page', value: rowsPerPage },
                        { key: 'page_no', value: page }
                    ])
                );
                if (response.data.success) {
                    if (response.data?.response?.data) {
                        setStudents(response.data.response?.data);
                        setCount(response.data.response?.totalCount);
                    }
                    setLoading(false);
                }
            } catch (e) {
                console.log(e);
                setLoading(false);
                setSnackbar({ open: true, message: langString('servererror') });
            }
        };
        init();
    }, [search, rowsPerPage, page]);

    return (
        <Wrapper
            title={langString('enrolmentrequests')}
            icon={<GroupAddIcon />}
            snakbar={snackbar}
            snackbarClose={() => setSnackbar({ open: false, message: '' })}
            loading={loading}
        >
            <Grid container sx={{ marginBottom: 2 }}>
                <Grid item sm={4}>
                    <TextField
                        id="outlined-search"
                        value={search}
                        name="search"
                        size="small"
                        onChange={(event: any) => setSearch(event.target.value)}
                        label={langString('search')}
                        placeholder={langString('search')}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item sm={12}>
                    <BasicDataTable
                        idField="id"
                        rows={students}
                        columns={StudentColumns}
                        showSL
                        count={count}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        setPage={(value: number) => setPage(value)}
                        setRowsPerPage={(value: number) => setRowsPerPage(value)}
                    />
                </Grid>
            </Grid>
        </Wrapper>
    );
}
