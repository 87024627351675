export const sessionData = [
    {
        label: 'All',
        value: ''
    },
    {
        label: 'Summer',
        value: 's'
    },
    {
        label: 'Winter',
        value: 'w'
    },
    {
        label: 'March',
        value: 'm'
    }
];

export const yearData = [
    {
        label: '2011',
        value: 11
    },
    {
        label: '2012',
        value: 12
    },
    {
        label: '2013',
        value: 13
    },
    {
        label: '2014',
        value: 14
    },
    {
        label: '2015',
        value: 15
    },
    {
        label: '2016',
        value: 16
    },
    {
        label: '2017',
        value: 17
    },
    {
        label: '2018',
        value: 18
    },
    {
        label: '2019',
        value: 19
    },
    {
        label: '2020',
        value: 20
    },
    {
        label: '2021',
        value: 21
    }
];

export const variantData = [
    {
        label: 'QP-11',
        value: 'qp_11'
    },
    {
        label: 'QP-12',
        value: 'qp_12'
    },
    {
        label: 'QP-13',
        value: 'qp_13'
    },
    {
        label: 'QP-21',
        value: 'qp_21'
    },
    {
        label: 'QP-22',
        value: 'qp_22'
    },
    {
        label: 'QP-23',
        value: 'qp_23'
    }
];

// export default sessionData;
