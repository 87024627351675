// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, FormHelperText, Grid, TextField } from '@mui/material';

// project imports
import SubCard from 'ui-component/cards/SubCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { gridSpacing } from 'store/constant';
import axiosServices from 'utils/axiosServices';
import { makeFormData, requestUrl } from 'utils/Helpers';
import { useState } from 'react';
import Loader from 'ui-component/Loader';
import langString from 'utils/langString';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import FormValidators from 'utils/FormValidators';
// ==============================|| PROFILE 1 - CHANGE PASSWORD ||============================== //

const ChangePassword = () => {
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState<any>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const dispatch = useDispatch();

    const passwordChange = async () => {
        const allErrors = FormValidators(['oldPassword', 'newPassword', 'confirmPassword'], [oldPassword, newPassword, confirmPassword]);
        if (allErrors.valid && confirmPassword === newPassword) {
            setLoading(true);
            try {
                const response = await axiosServices.post(
                    requestUrl('user', 'change_password'),
                    makeFormData([
                        { key: 'old_password', value: oldPassword.trim() },
                        { key: 'new_password', value: newPassword.trim() }
                    ])
                );
                if (response.data.success) {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: response.data.msg,
                        variant: 'alert',
                        alertSeverity: 'success'
                    });
                    clearForm();
                } else {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: response.data.msg,
                        variant: 'alert',
                        alertSeverity: 'error'
                    });
                }
                setLoading(false);
            } catch (e) {
                console.log(e);
                setLoading(false);
            }
        } else {
            setErrors(allErrors);
        }
        setIsSubmitting(false);
    };

    const clearForm = () => {
        setErrors(null);
        setNewPassword('');
        setConfirmPassword('');
        setOldPassword('');
    };

    return (
        <Grid container spacing={gridSpacing}>
            {loading && <Loader />}
            <Grid item xs={12}>
                <SubCard title={langString('changepassword')}>
                    <form noValidate autoComplete="off">
                        <Grid container spacing={gridSpacing} sx={{ mb: 1.75 }}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    type="password"
                                    id="outlined-basic7"
                                    fullWidth
                                    label="Current Password"
                                    value={oldPassword}
                                    onChange={(event: any) => setOldPassword(event.target.value)}
                                />
                                {errors && errors.oldPassword?.required && (
                                    <FormHelperText error id="helper-text-oldPassword">
                                        {errors.oldPassword?.message}
                                    </FormHelperText>
                                )}
                            </Grid>
                        </Grid>
                        <Grid container spacing={gridSpacing} sx={{ mb: 1.75 }}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    type="password"
                                    id="outlined-basic8"
                                    fullWidth
                                    label="New Password"
                                    value={newPassword}
                                    onChange={(event: any) => setNewPassword(event.target.value)}
                                />
                                {errors && errors.newPassword?.required && (
                                    <FormHelperText error id="helper-text-newPassword">
                                        {errors.newPassword?.message}
                                    </FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    type="password"
                                    id="outlined-basic9"
                                    fullWidth
                                    label="Confirm Password"
                                    value={confirmPassword}
                                    onChange={(event: any) => setConfirmPassword(event.target.value)}
                                />
                                {confirmPassword && confirmPassword !== newPassword && (
                                    <FormHelperText error id="helper-text-name">
                                        {langString('confirmpasswordnotmatched')}
                                    </FormHelperText>
                                )}
                                {errors && errors.confirmPassword?.required && (
                                    <FormHelperText error id="helper-text-confirmPassword">
                                        {errors.confirmPassword?.message}
                                    </FormHelperText>
                                )}
                            </Grid>
                        </Grid>
                    </form>
                    <Grid spacing={2} container justifyContent="flex-end" sx={{ mt: 3 }}>
                        <Grid item>
                            <AnimateButton>
                                <Button variant="contained" onClick={() => passwordChange()} disabled={isSubmitting}>
                                    Change Password
                                </Button>
                            </AnimateButton>
                        </Grid>
                        <Grid item>
                            <Button sx={{ color: theme.palette.error.main }} onClick={() => clearForm()} disabled={isSubmitting}>
                                Clear
                            </Button>
                        </Grid>
                    </Grid>
                </SubCard>
            </Grid>
        </Grid>
    );
};

export default ChangePassword;
