import { useState, useEffect } from 'react';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import Wrapper from 'components/Card/Wrapper';
import { useSelector } from 'react-redux';
import { DefaultRootStateProps } from 'types';
import { SnackBarType } from 'types/common';
import axiosServices from 'utils/axiosServices';
import { makeFormData, requestUrl } from 'utils/Helpers';
import langString from 'utils/langString';
import DashboardCourses from '../Courses';
import StudentProfileDetails from './Details';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import StudentProfileChart from './Chart';

export default function DashboardStudentProfile() {
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState<SnackBarType>({ open: false, message: '' });
    const [courses, setCourses] = useState<any[]>([]);
    const [student, setStudent] = useState<any>(null);
    const { studentID } = useParams();

    const course = useSelector((state: DefaultRootStateProps) => state.courseData);

    useEffect(() => {
        const init = async () => {
            setLoading(true);
            try {
                const response = await axiosServices.post(
                    requestUrl('dashboard', 'student_details'),
                    makeFormData([{ key: 'user_id', value: studentID || 0 }])
                );
                if (response.data.success) {
                    if (response.data?.response) {
                        setStudent(response.data.response?.userDetails);
                        setCourses(response.data.response?.enrolledCourses);
                    }
                    setLoading(false);
                }
            } catch (e) {
                console.log(e);
                setLoading(false);
                setSnackbar({ open: true, message: langString('servererror') });
            }
        };
        if (studentID) {
            init();
        } else {
            setSnackbar({ open: true, message: langString('coursenotselected') });
        }
    }, [studentID]);

    return (
        <Wrapper
            title={langString('studentprofile')}
            icon={<AccountBoxIcon />}
            snakbar={snackbar}
            snackbarClose={() => setSnackbar({ open: false, message: '' })}
            loading={loading}
        >
            <StudentProfileDetails student={student} />
            <Box sx={{ marginY: 2 }}>
                <DashboardCourses courses={courses} selectedID={course?.id} />
            </Box>
            {studentID && course && <StudentProfileChart studentID={studentID} courseID={course?.id} />}
        </Wrapper>
    );
}
