import { Typography, Grid } from '@mui/material';
import ContentViewerAns from 'components/common/ContentViewerAns';
import _ from 'lodash';

export default function AnswerSheet({ questions, divRef, title }: { questions: any; divRef: any; title?: string }) {
    return (
        <div className="answerSheet" ref={divRef}>
            {title && (
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item>
                        <Typography variant="h2" component="h2">
                            Quiz - {title.toUpperCase()}
                        </Typography>
                    </Grid>
                </Grid>
            )}
            <Typography variant="h3" sx={{ textDecoration: 'underline', marginY: 2 }}>
                Answer Sheet
            </Typography>
            <Grid container>
                {questions &&
                    questions.map((question: any, key: any) => {
                        const ansIndex = _.findIndex(question.answers, { fraction: '1.0000000' });
                        return (
                            <Grid item xs={3} marginY={1}>
                                <div
                                    style={{
                                        display: 'grid',
                                        gridTemplateColumns: '0.025fr 1fr',
                                        alignItems: 'center',
                                        fontSize: '20px'
                                    }}
                                >
                                    <Typography variant="h3">{key + 1}.</Typography>
                                    <ContentViewerAns ansKey={ansIndex} />
                                </div>
                            </Grid>
                        );
                    })}
            </Grid>
        </div>
    );
}
