import { useState, useEffect } from 'react';
import { Box, FormControl, Grid, Typography, InputLabel, Select, MenuItem, Button } from '@mui/material';
import { LineOptions, BarSeries, BarOptions } from 'utils/ChartTypes';
import ReactApexChart from 'react-apexcharts';
import axiosServices from 'utils/axiosServices';
import { makeFormData, marksColor, requestUrl, sortedDataList } from 'utils/Helpers';
import langString from 'utils/langString';

const chartToolbar = {
    show: true,
    offsetX: 0,
    offsetY: 0,
    tools: {
        download: true,
        selection: true,
        zoom: true,
        zoomin: true,
        zoomout: true,
        pan: true,
        reset: true,
        customIcons: []
    },
    export: {
        csv: {
            filename: 'AverageMarkComp',
            columnDelimiter: ',',
            headerCategory: 'Student Name',
            headerValue: 'value',
            dateFormatter(timestamp: any) {
                return new Date(timestamp).toDateString();
            }
        },
        svg: {
            filename: 'AverageMarkComp'
        },
        png: {
            filename: 'AverageMarkComp'
        }
    },
    autoSelected: 'zoom'
};

type StudentProfileChartPropsType = {
    studentID: number | string;
    courseID: number | string;
};
export default function StudentProfileChart({ studentID, courseID }: StudentProfileChartPropsType) {
    const [options, setOptions] = useState<BarOptions | null>(null);
    const [lineOptions, setLineOptions] = useState<LineOptions | null>(null);
    const [labels, setLabels] = useState([]);
    const [series, setSeries] = useState<BarSeries[]>([]);
    const [chartType, setChartType] = useState('bar');
    const [sortBy, setSortBy] = useState('def');

    const handleChartChange = (type: string) => {
        setChartType(type);
        setLineOptions({
            chart: {
                height: 'auto',
                type: 'line',
                zoom: {
                    enabled: true
                },
                toolbar: chartToolbar
            },
            // colors: ['#2E93fA'],
            dataLabels: {
                enabled: false
            },
            title: {
                text: 'Quiz Result Overview',
                align: 'left'
            },
            stroke: {
                curve: 'straight'
            },
            xaxis: {
                categories: labels
            },
            markers: {
                size: 4,
                colors: '#2E93fA',
                strokeColors: '#fff'
            }
        });
    };

    useEffect(() => {
        const init = async () => {
            try {
                const response = await axiosServices.post(
                    requestUrl('dashboard', 'student_report'),
                    makeFormData([
                        { key: 'course_id', value: courseID },
                        { key: 'user_id', value: studentID }
                    ])
                );
                if (response.data.success) {
                    if (response.data?.response && response.data?.response?.quizGrade && response.data?.response?.quizGrade.length) {
                        const labelsArray = response.data.response?.quizGrade.map((item: any) => item.quizname);
                        setLabels(labelsArray);
                        const dataList = response.data.response?.quizGrade.map((item: any) => item.gradePercent);
                        const sortedData: number[] = sortedDataList(dataList, sortBy);
                        setSeries([{ name: '%', data: dataList }]);
                        const barColors: string[] = sortedData.map((mark: number) => marksColor(mark));
                        setSeries([{ name: '%', data: sortedData }]);
                        setOptions({
                            chart: {
                                type: 'bar',
                                height: 350,
                                toolbar: chartToolbar
                            },
                            title: {
                                text: 'Quiz Result Overview',
                                align: 'left'
                            },
                            colors: barColors,
                            plotOptions: {
                                bar: {
                                    distributed: true,
                                    borderRadius: 4,
                                    horizontal: false
                                }
                            },
                            dataLabels: {
                                enabled: false
                            },
                            xaxis: {
                                categories: labelsArray
                            }
                        });
                    } else {
                        setOptions(null);
                    }
                }
            } catch (e) {
                console.log(e);
            }
        };
        if (courseID && studentID) {
            init();
        }
    }, [studentID, courseID, sortBy]);

    return (
        <Box sx={{ marginY: 3, borderBottom: '1px solid #DDD', padding: 2 }}>
            <Grid container spacing={2}>
                <Grid item sm={12}>
                    <FormControl sx={{ paddingRight: 1, minWidth: '150px', float: 'right' }}>
                        <InputLabel id="sort-select-label">{langString('sort')}</InputLabel>
                        <Select
                            size="small"
                            name="sorts"
                            labelId="sort-select-label"
                            id="sort-select"
                            value={sortBy}
                            label={langString('sort')}
                            onChange={(event: any) => setSortBy(event.target.value)}
                        >
                            <MenuItem value="def">Default</MenuItem>
                            <MenuItem value="asc">Ascending</MenuItem>
                            <MenuItem value="desc">Descending</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ marginY: 1 }}>
                <Grid item xs={12} marginTop={2}>
                    <Grid container spacing={8} justifyContent="center" alignItems="center">
                        <Grid item>
                            <Button
                                variant="contained"
                                sx={{ backgroundColor: chartType === 'bar' ? '#00807e' : '#cfcfcf' }}
                                onClick={() => handleChartChange('bar')}
                            >
                                Bar Chart
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                sx={{ backgroundColor: chartType === 'line' ? '#00807e' : '#cfcfcf' }}
                                onClick={() => handleChartChange('line')}
                            >
                                Trend Line
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sm={12} sx={{ display: chartType !== 'bar' ? 'none' : '' }}>
                    {options && series ? (
                        <ReactApexChart options={options} series={series} type="bar" height="auto" />
                    ) : (
                        <Typography variant="h3" sx={{ textAlign: 'center' }}>
                            {langString('nodatafound')}
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={12} sx={{ display: chartType !== 'line' ? 'none' : '' }}>
                    {lineOptions && series ? (
                        <ReactApexChart options={lineOptions} series={series} type="line" height="auto" />
                    ) : (
                        <Typography variant="h3" sx={{ textAlign: 'center' }}>
                            {langString('nodatafound')}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
}
