// import React from 'react';
import { Box, Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import userRound from 'assets/images/users/user-round.svg';

type StudentProfileDetailsProps = {
    student: any;
};
export default function StudentProfileDetails({ student }: StudentProfileDetailsProps) {
    return (
        <Grid container sx={{ marginBottom: 4 }}>
            <Grid item md={6}>
                <Card sx={{ display: 'flex' }}>
                    <CardMedia component="img" sx={{ width: 100 }} image={student?.profile_pic || userRound} alt={student?.firstname} />
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent sx={{ flex: '1 0 auto' }}>
                            <Typography component="div" variant="h5">
                                {student?.firstname} {student?.lastname}
                            </Typography>
                        </CardContent>
                    </Box>
                </Card>
            </Grid>
            <Grid item md={3}>
                <Typography variant="subtitle1" component="div">
                    Student ID : {student?.userid}
                </Typography>
                <Typography variant="subtitle1" component="div">
                    Email : {student?.email}
                </Typography>
            </Grid>
            <Grid item md={3}>
                <Typography variant="subtitle1" component="div">
                    Username : {student?.username}
                </Typography>
                <Typography variant="subtitle1" component="div">
                    Phone : {student?.phone}
                </Typography>
            </Grid>
        </Grid>
    );
}
