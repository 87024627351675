import { Box, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import langString from 'utils/langString';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useSelector } from 'react-redux';
import { DefaultRootStateProps } from 'types';
import { useEffect, useState } from 'react';
import { makeFormData, requestUrl } from 'utils/Helpers';
import axiosServices from 'utils/axiosServices';

type SavedQuizesFilterPropsType = {
    topic: string[];
    setTopic: (values: string[]) => void;
    difficulty: number;
    setDifficulty: (value: number) => void;
    search: string;
    setSearch: (value: string) => void;
    quizes: any[];
};
export default function SavedQuizesFilter({
    topic,
    setTopic,
    difficulty,
    setDifficulty,
    search,
    setSearch,
    quizes
}: SavedQuizesFilterPropsType) {
    const course = useSelector((state: DefaultRootStateProps) => state.courseData);
    const [topicList, setTopicList] = useState<any[]>([]);
    const topicOptions = createFilterOptions({
        matchFrom: 'any', // start or any
        stringify: (option: any) => option.name
    });

    const topicChange = (event: any, value: any) => {
        if (value) {
            const topicIDs = value.map((item: any) => item.id);
            setTopic(topicIDs);
        }
    };

    useEffect(() => {
        const init = async () => {
            try {
                const response = await axiosServices.post(
                    requestUrl('ace_exam', 'get_topics'),
                    makeFormData([
                        { key: 'course_id', value: course?.id || 0 },
                        { key: 'category_id', value: course?.categoryID || 0 }
                    ])
                );
                if (response.status === 200 && response.data.success) {
                    if (response.data?.response) {
                        setTopicList(response.data.response);
                    }
                }
            } catch (e) {
                console.log(e);
            }
        };
        init();
    }, [course.categoryID, course.id]);

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item sm={4}>
                    <FormControl fullWidth>
                        <Autocomplete
                            size="small"
                            multiple
                            id="multiple-limit-tags"
                            options={topicList}
                            getOptionLabel={(option) => option.name}
                            filterOptions={topicOptions}
                            renderInput={(params) => (
                                <TextField {...params} label={langString('topics')} placeholder={langString('topics')} />
                            )}
                            onChange={(event: any, value: any) => topicChange(event, value)}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={4}>
                    <FormControl fullWidth>
                        <InputLabel id="difficulty-select-label">{langString('difficulty')}</InputLabel>
                        <Select
                            size="small"
                            name="difficultys"
                            labelId="difficulty-select-label"
                            id="difficulty-select"
                            value={difficulty}
                            label={langString('difficulty')}
                            onChange={(event: any) => setDifficulty(event.target.value)}
                        >
                            <MenuItem value={0}>
                                {langString('select')} {langString('difficulty')}
                            </MenuItem>
                            {course &&
                                course.difficulties &&
                                course.difficulties.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item sm={4}>
                    <FormControl fullWidth>
                        <TextField
                            size="small"
                            id="outlined-search"
                            value={search}
                            autoFocus
                            name="search"
                            onChange={(event: any) => setSearch(event.target.value)}
                            label={langString('search')}
                            placeholder={langString('search')}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </Box>
    );
}
