import { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import Wrapper from 'components/Card/Wrapper';
import langString from 'utils/langString';
import axiosServices from 'utils/axiosServices';
import { SnackBarType } from 'types/common';
import { checkNullInfo, requestUrl } from 'utils/Helpers';
import BasicDataTable, { ColumnType } from 'components/Table/BasicDataTable';
import AssessmentIcon from '@mui/icons-material/Assessment';

export default function Payments() {
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState<SnackBarType>({ open: false, message: '' });
    const [payments, setPayments] = useState<any[]>([]);

    const PaymentColumns: ColumnType[] = [
        {
            header: langString('name'),
            accessor: 'name',
            content: (item: any) => <Typography>{checkNullInfo(item?.name)}</Typography>
        },
        {
            header: langString('date'),
            accessor: 'date',
            content: (item: any) => <Typography>{checkNullInfo(item?.date)}</Typography>
        },
        {
            header: langString('amount'),
            accessor: 'amount',
            content: (item: any) => <Typography>{checkNullInfo(item?.amount)}</Typography>
        },
        {
            header: langString('package'),
            accessor: 'package',
            content: (item: any) => <Typography>{checkNullInfo(item?.package)}</Typography>
        }
    ];

    useEffect(() => {
        const init = async () => {
            setLoading(true);
            try {
                const response = await axiosServices.post(requestUrl('ace_exam', 'quizes'));
                if (response.data.success) {
                    if (response.data?.response) {
                        setPayments(response.data.response);
                    }
                    setLoading(false);
                }
            } catch (e) {
                console.log(e);
                setLoading(false);
                setSnackbar({ open: true, message: langString('servererror') });
            }
        };
        init();
    }, []);

    return (
        <Wrapper
            title={langString('payments')}
            icon={<AssessmentIcon />}
            snakbar={snackbar}
            snackbarClose={() => setSnackbar({ open: false, message: '' })}
            loading={loading}
        >
            <Grid container>
                <Grid item sm={12}>
                    <BasicDataTable rows={payments} columns={PaymentColumns} showSL />
                </Grid>
            </Grid>
        </Wrapper>
    );
}
