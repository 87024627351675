import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { Checkbox, TablePagination, TableRow } from '@mui/material';
import EnhancedTableToolbar from './Toolbar';
import EnhancedTableHead, { HeadCellType, OrderType } from './Header';
import { checkDecimal, checkNullInfo } from 'utils/Helpers';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
function getComparator<Key extends keyof any>(
    order: OrderType,
    orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

type EnhancedTablePropsType = {
    idField: string;
    rows: any[];
    headerCells: HeadCellType[];
    selectable?: boolean;
    toolbar?: boolean;
    totalRows?: number;
    page?: number;
    rowsPerPage?: number;
    handleChangePage?: (event: unknown, newPage: number) => void;
    handleChangeRowsPerPage?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};
export default function EnhancedTable({
    idField,
    rows,
    headerCells,
    selectable,
    toolbar,
    totalRows,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage
}: EnhancedTablePropsType) {
    const [order, setOrder] = React.useState<OrderType>('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState<readonly string[]>([]);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.name);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: readonly string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };

    const isSelected = (name: string) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = () => {
        if (page && rowsPerPage) {
            return page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
        }
        return 0;
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2, border: '1px solid #EEE', padding: 2 }}>
                {toolbar && <EnhancedTableToolbar numSelected={selected.length} />}
                <TableContainer>
                    <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            headCells={headerCells}
                            selectable={false}
                        />
                        <TableBody>
                            {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                                const isItemSelected = isSelected(row[idField].toString());
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) => handleClick(event, row[idField].toString())}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row[idField]}
                                        selected={isItemSelected}
                                    >
                                        {selectable && (
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId
                                                    }}
                                                />
                                            </TableCell>
                                        )}
                                        {headerCells &&
                                            headerCells.length &&
                                            headerCells.map((cell: HeadCellType) => (
                                                <TableCell
                                                    sx={{ paddingY: 1 }}
                                                    key={row[cell.id]}
                                                    component="th"
                                                    scope="row"
                                                    padding="none"
                                                    align={cell.numeric ? 'right' : 'left'}
                                                >
                                                    {cell.numeric ? checkDecimal(row[cell.id]) : checkNullInfo(row[cell.id])}
                                                </TableCell>
                                            ))}
                                    </TableRow>
                                );
                            })}
                            {emptyRows() > 0 && (
                                <TableRow
                                    style={{
                                        height: 53 * emptyRows()
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {totalRows && rowsPerPage && page && handleChangePage && handleChangeRowsPerPage && (
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={totalRows}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                )}
            </Paper>
        </Box>
    );
}
