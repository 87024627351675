import { IQuestion } from 'types/common';
import { ADD_SELECTED_QUESTION, REMOVE_QUESTION, REMOVE_ALL_SELECTED_QUESTIONS, ADD_ALL_QUESTIONS } from './actions';

export interface questionReducerActionProps {
    type: string;
    question: IQuestion | null;
    allQuestions: Array<IQuestion> | null;
}

type questionReducerStateTypes = {
    selectedQuestions: Array<IQuestion>;
};

const initialState: questionReducerStateTypes = {
    selectedQuestions: []
};

const questionReducer = (state = initialState, action: questionReducerActionProps) => {
    const question = action.question || null;
    const allQuestions = action.allQuestions || null;
    switch (action.type) {
        case ADD_SELECTED_QUESTION:
            return {
                ...state,
                selectedQuestions: [...state.selectedQuestions, question]
            };
        case REMOVE_QUESTION:
            return {
                ...state,
                selectedQuestions: state.selectedQuestions.filter((ques) => question && ques.id !== question.id)
            };
        case REMOVE_ALL_SELECTED_QUESTIONS:
            return {
                ...state,
                selectedQuestions: []
            };
        case ADD_ALL_QUESTIONS:
            return {
                ...state,
                selectedQuestions: allQuestions
            };
        default:
            return state || null;
    }
};

export default questionReducer;
